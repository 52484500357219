<!-- eslint-disable tailwindcss/no-custom-classname -->
<script setup lang="ts">
import type { ModalState } from "~/types/modal";
import type { HomeImageType } from "~/types/rest_api_types/pages/home";
import { leadCollectionModalStateKey } from "~/types/injectKeys";

defineProps<{
  title?: string | null;
  description?: string | null;
  italicizeDescription?: boolean;
  image?: {
    src?: string | null;
    alt?: string | null;
    width?: string;
    height?: string;
  };
  list?: string[];
  showBorder?: boolean;
  cta?: {
    label?: string | null;
    link?: string;
  };
  hideImageInMobile?: boolean;
  featuredOn?: {
    title?: string;
    images?: HomeImageType[];
  };
}>();

const { toggleModal } = inject<ModalState>(leadCollectionModalStateKey, {
  modalOpen: ref(false),
  toggleModal: () => { },
});
</script>

<template>
  <div>
    <ULandingHero
      :ui="{
        wrapper: 'py-8 sm:py-12 md:py-32',
        title: 'text-4xl sm:text-5xl lg:text-5xl font-bold text-text-primary !leading-[1.2] tracking-[-0.02em] md:w-3/4 text-pretty',
        description: 'mt-8 max-w-xl md:max-w-lg lg:max-w-2xl text-text-paragraph tracking-normal font-semibold',
        base: 'text-left z-10',
        links: 'mt-4 justify-start',
      }"
    >
      <template #title>
        <slot name="title">
          {{ removeHtmlTags(title ?? "") }}
        </slot>
      </template>

      <template #description>
        <slot name="description">
          <span
            v-if="description"
            v-external-link
            :class="italicizeDescription ? 'italic font-serif' : 'normal'"
            v-html="description"
          />
        </slot>
      </template>

      <template #bottom>
        <div
          v-if="image?.src"
          class="hidden z-0 absolute inset-0 md:flex justify-end items-center mx-auto px-4 sm:px-6 lg:px-8 max-w-[1440px] max-h-[697px]"
        >
          <div class="relative flex justify-end md:w-1/3 xl:w-1/2 h-full">
            <div class="absolute inset-0 bg-gradient-to-t from-snow-50 via-snow-50/0 to-transparent" />
            <NuxtPicture
              sizes="(max-width: 640px) 398px, (max-width: 768px) 565px, (max-width: 1024px) 565px, (max-width: 1280px) 565px, (max-width: 1536px) 565px, 565px"
              :src="image?.src ?? ''"
              :alt="image?.alt ?? ''"
              :width="image?.width"
              :height="image?.height"
              :img-attrs="{
                class: 'max-h-full object-contain h-full',
              }"
              preload
              loading="eager"
              provider="cloudflare"
            />
          </div>
        </div>
      </template>

      <template #links>
        <div
          class="max-w-2xl"
        >
          <dl
            v-if="list"
            class="space-y-2 leading-relaxed"
          >
            <div
              v-for="(item, idx) in list"
              :key="idx"
              class="relative pl-8"
            >
              <dt>
                <IconCheck class="top-1.5 left-0 absolute size-4 text-text-primary" />
                <span
                  v-external-link
                  class="font-medium text-text-paragraph"
                  v-html="item"
                />
              </dt>
            </div>
          </dl>

          <div
            v-if="image?.src && !hideImageInMobile"
            class="md:hidden relative"
          >
            <div class="absolute inset-0 bg-gradient-to-b from-snow-50 via-snow-50/0 to-transparent" />
            <NuxtPicture
              provider="cloudflare"
              :src="image?.src ?? ''"
              :alt="image?.alt ?? undefined"
              :width="image?.width"
              :height="image?.height ? Number(image?.height) / 2 : undefined "
              :img-attrs="{
                class: 'max-h-full object-contain w-full',
              }"
              sizes="(max-width: 640px) 398px, (max-width: 768px) 565px, (max-width: 1024px) 565px, (max-width: 1280px) 565px, (max-width: 1536px) 565px, 565px"
              preload
              loading="eager"
            />
          </div>

          <UButton
            v-if="cta"
            :label="cta?.label ?? ''"
            color="red"
            class="justify-center items-center shadow-lg md:shadow md:mt-8 w-full md:w-auto font-semibold lead-collection-trigger"
            size="xl"
            :to="cta.link"
            @click="toggleModal"
          />

          <div
            v-if="featuredOn"
            class="mt-12"
          >
            <FeaturedOn
              :title="featuredOn?.title ?? ''"
              :images="featuredOn?.images ?? []"
            />
          </div>
        </div>
      </template>
    </ULandingHero>

    <UContainer v-if="showBorder">
      <div class="relative">
        <UDivider
          class="w-full"
          size="2xs"
        />

        <NuxtPicture
          provider="cloudflare"
          src="/gradient-border.png"
          width="67"
          height="7"
          class="top-0 left-0 absolute"
        />
      </div>
    </UContainer>
  </div>
</template>
